import { render, staticRenderFns } from "./loginfooter.vue?vue&type=template&id=37a0a609&scoped=true&"
import script from "./loginfooter.vue?vue&type=script&lang=js&"
export * from "./loginfooter.vue?vue&type=script&lang=js&"
import style0 from "./loginfooter.vue?vue&type=style&index=0&id=37a0a609&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37a0a609",
  null
  
)

export default component.exports